import React, {useEffect, useState} from 'react';
import * as styles from './promo.module.scss';
import classNames from "classnames";
import {Image} from "react-bootstrap";



function Promo() {


    return (
        <div className={styles.promo}>
            <div className={styles.promo__container}>
                <div className={classNames([`${styles.promo__row}`], [`${styles.promo__rowTitle}`])}>
                    <div className={styles.promo__title}>
                        <h1>Мы оказываем услуги</h1>
                    </div>
                </div>

                <div className={styles.promo__row}>
                    <div className={styles.promo__cell}>
                        <ul>
                            <li>Лечение кариеса</li>
                            <li>Восстановление формы и функций зуба</li>
                            <li>Лечение каналов зубов</li>
                            <li>Снятие зубных отложений</li>
                            <li>Профессиональная гигиена полости рта</li>
                            <li>Профилактика кариеса</li>
                        </ul>

                    </div>
                    <div className={styles.promo__photo}>
                        <Image src="/assets/promo2.jpg" rounded />
                    </div>
                </div>

                <div className={styles.promo__row}>
                    <div className={styles.promo__photo}>
                        <Image src="/assets/promo1.jpg" rounded />
                    </div>
                    <div className={styles.promo__cell}>
                        <ul>
                            <li>Протезирование мостовидными протезами (литые, металлокерамика)</li>
                            <li>Протезирование съёмными протезами (пластиночные пластмассовые, бюгельные)</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Promo;