import * as React from "react"
import Header from "../components/header/header";
import Footer from "components/footer/footer.jsx";
import BackToTop from "components/back-to-top/back-to-top.jsx";
import Seo from "components/seo/seo.jsx";
import Promo from "components/promo/promo.jsx";


const IndexPage = () => {



  return (
      <React.Fragment>
        <Seo />
        <Header />
        <Promo />
        <Footer />
        <BackToTop />
      </React.Fragment>

  )
}

export default IndexPage
